@import "../partials/base";
// Common
div.home-title h2 {
    font-size: 30px;
}
.header-bar {
    z-index: 12;
}
// Banner Section
.main-banner-a-wrapper {
    width: 100%;
    margin-top: 64px;
    min-height: 640px;
    background: #fff get_prefix_url("imgs/main/banner_a.jpg") no-repeat center center;
    background-size: cover;
    position: relative;
    .banner-main {
        position: absolute;
        right: 0;
        top: 0;
        width: 58%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: rgba(0, 0, 0, .6);
    }
    .main-info {
        margin: 0 5%;
        max-width: 700px;
        color: #fff;
    }
    .slogan-title {
        font-size: 28px;
        line-height: 1.2;
        font-weight: bold;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.8);
        .main-zh & {
            font-size: 36px;
            line-height: 1.3;
        }
    }
    .banner-desc {
        font-size: 28px;
        font-weight: normal;
        line-height: 1.2;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.8);
        margin-top: 28px;
    }
    .banner-link-wrap {
        margin-top: 40px;
        .btn {
            width: 250px;
            height: 43px;
            line-height: 43px;
        }
        .link-free {
            margin-left: 38px;
            color: #f8d340;
            text-decoration: underline;
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.main-banner-b-wrapper {
    width: 100%;
    margin-top: 64px;
    min-height: 640px;
    background: #fff get_prefix_url("imgs/main/banner_a.jpg") no-repeat center center;
    background-size: cover;
    position: relative;
    .banner-main {
        position: absolute;
        right: 0;
        top: 0;
        width: 58%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .main-info {
        margin: 0 5%;
        max-width: 600px;
        padding: 50px 40px;
        color: #fff;
        background: rgba(50, 74, 94, 0.72);
        box-shadow: 0px 6px 12px 4px rgba(0, 0, 0, 0.35);
        border-radius: 24px;
    }
    .slogan-title {
        font-size: 25px;
        line-height: 1.2;
        font-weight: bold;
        text-align: center;
    }
    .banner-desc {
        font-size: 16px;
        line-height: 1.2;
        margin-top: 24px;
        text-align: center;
    }
    .main-form-wrap {
        .mfw-title {
            font-size: 20px;
            line-height: 1.3;
            font-weight: bold;
            text-align: center;
            color: #fff;
        }
        .banner-form {
            margin: 32px 0 0;
        }
        .input-group {
            display: flex;
            justify-content: space-between;
            margin-bottom: 13px;
        }
        .input-ctrl {
            width: 49%;
        }
        .input-select {
            position: relative;
            .dropdown-menu {
                width: 100%;
                .dropdown-item:hover, .dropdown-item:focus {
                    color: #00c4b3;
                }
                .dropdown-item.active, .dropdown-item:active {
                    color: #00c4b3;
                    background: #f8f9fa;  
                }
            }
        }
        .txt {
            width: 100%;
            min-height: 46px;
            padding: 0 10px;
            color: #000;
            font-size: 13px;
            font-weight: bold;
            background: #fff;
            border: 1px solid #e7e7e7;
            border-radius: 0!important;
            &::placeholder {
                color: #999;
            }
            &.txt-in-date {
                background: #fff get_prefix_url("imgs/tvmall/sel.png") no-repeat 95% center;
            }
            &.has-arrow {
                background: #fff get_prefix_url("imgs/tvmall/sel.png") no-repeat 95% center;
            }
        }
        .button-wrap {
            padding-top: 5px;
            .for-free-cons {
                text-align: right;
                line-height: 48px;
                a {
                    color: #f8d340;
                    text-decoration: underline;
                }
            }
            .btn {
                width: 100%;
                text-transform: uppercase;
                &[disabled] {
                    background: #e4e4e4;
                    cursor: not-allowed;
                    color: #fff;
                }
            }
        }
    }
}

.main-banner-c-wrapper {
    width: 100%;
    margin-top: 64px;
    min-height: 730px;
    background: #fff get_prefix_url("imgs/main/banner.jpg") no-repeat center center;
    background-size: cover;
    position: relative;
    .banner-main {
        position: absolute;
        right: 0;
        top: 0;
        width: 58%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .main-info {
        margin: 0 5%;
        max-width: 600px;
        padding: 34px 40px;
        color: #fff;
        background: rgba(50, 74, 94, 1);
        box-shadow: 0px 6px 12px 4px rgba(0, 0, 0, 0.35);
        border-radius: 24px;
    }
    .slogan-title {
        font-size: 25px;
        line-height: 1.2;
        font-weight: bold;
        text-align: center;
    }
    .banner-desc {
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
    }
    .main-form-wrap {
        .mfw-title {
            font-size: 20px;
            line-height: 1.3;
            font-weight: bold;
            text-align: center;
            color: #fff;
        }
        .banner-form {
            margin: 32px 0 0;
        }
        .input-group {
            display: flex;
            justify-content: space-between;
            margin-bottom: 13px;
        }
        .input-ctrl {
            width: 49%;
        }
        .input-select {
            position: relative;
            .dropdown-menu {
                width: 100%;
                .dropdown-item:hover, .dropdown-item:focus {
                    color: #00c4b3;
                }
                .dropdown-item.active, .dropdown-item:active {
                    color: #00c4b3;
                    background: #f8f9fa;  
                }
            }
            .hc-for-types {
                max-height: 300px;
                overflow-y: auto;
            }
        }
        .txt {
            width: 100%;
            min-height: 46px;
            padding: 0 10px;
            color: #000;
            font-size: 13px;
            font-weight: bold;
            background: #fff;
            border: 1px solid #e7e7e7;
            border-radius: 0!important;
            &::placeholder {
                color: #999;
            }
            &.txt-in-date {
                background: #fff get_prefix_url("imgs/tvmall/sel.png") no-repeat 95% center;
            }
            &.has-arrow {
                background: #fff get_prefix_url("imgs/tvmall/sel.png") no-repeat 95% center;
            }
        }
        .button-wrap {
            padding-top: 5px;
            .for-free-cons {
                text-align: right;
                line-height: 48px;
                a {
                    color: #f8d340;
                    text-decoration: underline;
                }
            }
            .btn {
                width: 100%;
                text-transform: uppercase;
                &[disabled] {
                    background: #e4e4e4;
                    cursor: not-allowed;
                    color: #fff;
                }
            }
        }
    }
}

// How It Works Section
.main-howitworks-wrapper {
    padding: 104px 16px 100px;
    .how-it-works-wrapper {
        margin: 0 auto;
        max-width: 800px;
    }
    .hiw-list {
        .item {
            margin-top: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:nth-child(2n) {
                .item-info {
                    order: 2;
                }
                .item-img {
                    order: 1;
                    margin: 0 10px 0 0;
                }
            }
        }
        .item-info {
            flex: 1;
            display: flex;
            align-items: center;
        }
        .item-img {
            width: 300px;
            text-align: center;
            margin-left: 10px;
            img {
                max-width: 100%;
            }
        }
        .item-id {
            font-size: 120px;
            font-weight: bold;
            font-style: italic;
            color: #e7e7e7;
        }
        .item-base {
            flex: 1;
            margin-left: 20px;
            .item-name {
                font-size: 18px;
                font-weight: bold;
                color: #000;
                a {
                    color: #00c4b3;
                    text-decoration: underline;
                }
            }
            .item-desc {
                margin-top: 4px;
                font-size: 16px;
                color: #444;
                line-height: 1.2;
            }
        }
    }
}

// Services Section
.main-services-wrapper {
    background: #f8f8f8;
    padding: 104px 16px 100px;
    .for-subtitle {
        margin-top: 20px;
        text-align: center;
    }
    .services-list {
        margin: 40px auto 0;
        max-width: 1008px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
            width: 19%;
            height: 365px;
            display: flex;
            align-items: center;
            .inner {
                border: 1px solid #ecebeb;
                border-radius: 5px;
                background: #fff;
                height: 80%;
                width: 100%;
                padding: 5px 10px;
                transition: height .3s;
            }
            .item-img {
                display: flex;
                align-items: center;
                height: 100px;
                justify-content: center;
                margin-top: 50px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .item-name {
                
                font-size: 18px;
                line-height: 1.2;
                color: #324a5e;
                font-weight: bold;
                text-align: center;
                margin-top: 10px;
            }
            .item-desc {
                font-size: 14px;
                color: #444;
                line-height: 1.2;
                height: 0;
                overflow: hidden;
                opacity: 0;
                transition: opacity 1s;
                text-align: center;
                .main-zh & {
                    line-height: 1.6;
                }
            }
            .inner:hover, .inner.cur {
                border: 3px solid #F8D340;
                box-shadow: 0px 0px 10px rgba(51, 51, 51, 0.18);
                border-radius: 10px;
                height: 100%;
                .item-img {
                    height: 68px;
                    margin-top: 0;
                }
                .item-name {
                    margin-top: 0;
                    font-size: 16px;
                }
                .item-desc {
                    height: auto;
                    margin-top: 4px;
                    opacity: 1;
                }
            }
        }
    }
    .solution-btn-wrap {
        text-align: center;
        margin-top: 30px;
        .btn {
            text-transform: uppercase;
        }
    }
}

// Features Seciton
.main-features-wrapper {
    background: #e9f8f7;
    padding: 104px 16px 170px;
    .fs-title {
        margin-top: 30px;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        color: #000;
    }
    .mfs-main {
        display: flex;
        justify-content: space-between;
        margin: 76px auto 0;
        max-width: 1008px;
    }
    .fea-banner {
        margin-right: 14px;
        width: 500px;
        height: 680px;
        position: relative;
        .swiper-container {
            width: 100%;
            height: 100%;
            .swiper-slide img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .swiper-pagination {
            left: 50%;
            bottom: -50px;
            transform: translateX(-50%);
            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                margin: 0 6px;
                background: #26aea3;
                opacity: .4;
                outline: none;
                &.swiper-pagination-bullet-active {
                    background: #26aea3;
                    opacity: 1;
                }
                &:focus {
                    
                }
            }
        }
    }
    .fea-list {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item {
            width: 100%;
            border: 1px solid #dfe2e5;
            background: #fff;
            padding: 7px 14px;
            .item-header {
                display: flex;
                align-items: flex-start;
                background: get_prefix_url("imgs/main/dropdown.png") no-repeat 99% center;
                .item-icon {
                    width: 32px;
                    height: 32px;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                        text-align: center;
                    }
                }
                .item-name {
                    font-size: 16px;
                    line-height: 1.2;
                    font-weight: bold;
                    color: #465e72;
                    margin: 5px 0 0 5px;
                    flex: 1;
                }
            }
            .item-content {
                margin: 8px 0;
                font-size: 14px;
                line-height: 16px;
                color: #324a5e;
                padding-left: 6px;
                display: none;
                height: 80px;
            }
            &.cur {
                .item-header {
                    background: get_prefix_url("imgs/main/dropup.png") no-repeat 99% center;
                    .item-name {
                        color: #26aea3;
                    }
                }
                .item-content {
                    display: block;
                }
            }
        }
    }
}

// Have a question Section
.main-quesiton-wrapper {
    background: #00c4b3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 0 48px;
    .question-title {
        font-size: 24px;
        color: #f8d340;
        font-weight: bold;
    }
    .question-desc {
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        a {
            color: #fff;
            font-weight: bold;
        }
    }
    .question-form-ele {
        margin-top: 20px;
        input {
            border: 0 none;
            border-left: 1px solid #cdfffb;
            border-bottom: 1px solid #cdfffb;
            margin-right: 19px;
            height: 32px;
            width: 269px;
            color: #fff;
            
            
            background: transparent;
            padding-left: 12px;
            font-size: 16px;
            font-weight: 500;
            &::placeholder {
                color: #fafafa;
            }
        }
        button {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            width: 159px;
        }
    }
}

// Comparisont table section
.comparison-wrapper {
    padding: 104px 16px 115px;
    .compare-table-wrap {
        margin: 37px auto 0;
        max-width: 867px;
        background: #fff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        padding: 28px 30px 38px;
        table {
            width: 100%;
            border-collapse: collapse;
            th {
                width: 33%;
                height: 0;
                padding: 0;
                > div {
                    padding: 18px 0;
                    margin: 0 5px 0 5px !important;
                    height: 100%;
                    // box-sizing: content-box;
                    font-size: 16px;
                    text-align: center;
                    @media not all and (min-resolution:.001dpcm) { 
                        @supports (-webkit-appearance:none) {
                            box-sizing: content-box;
                        }
                    }
                }
                &:nth-child(1) {
                    width: 34%;
                }
                &:nth-child(2) {
                    > div {
                        background: #00c4b3;
                        color: #324a5e;
                        font-weight: bold;
                    }
                }
                &:nth-child(3) {
                    > div {
                        background: #e7e7e7;
                        color: #666;
                        font-weight: normal;
                    }
                }
            }
            tr {
                td {
                    height: 0;
                    padding: 0;
                    > div {
                        font-size: 16px;
                        font-weight: 500;
                        color: #444;
                        height: 100%;
                        margin: 0 5px;
                        padding: 10px 0;
                        text-align: center;
                        // box-sizing: content-box;
                        @media not all and (min-resolution:.001dpcm) { 
                            @supports (-webkit-appearance:none) {
                                box-sizing: content-box;
                            }
                        }
                    }
                    &:nth-child(1) > div {
                        text-align: left;
                    }
                    &:nth-child(2) > div {
                        background: #d5efed;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    &:nth-child(3) > div {
                        background: #f6f5f5;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                &:nth-child(2n + 1) {
                    td:nth-child(2) > div {
                        background: #e9f8f7;
                    }
                    td:nth-child(3) > div {
                        background: #fafafa;
                    }
                }
                &:nth-child(n + 8) {
                    display: none;
                }
            }
        }
        .btn-show-more {
            text-align: center;
            margin-top: 32px;
            .btn-show {
                cursor: pointer;
                width: 194px;
                height: 38px;
                border: 2px solid #324a5e;
                border-radius: 99em;
                color: #324a5e;
                font-size: 16px;
                font-weight: bold;
                outline: none;
                position: relative;
                background: transparent;
                .main-en & {
                    font-size: 14px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }
                &::after {
                    content: "";
                    background: transparent get_prefix_url("imgs/main/triangle.png") no-repeat 90% center;
                    width: 10px;
                    height: 12px;
                    position: absolute;
                    top: 50%;
                    right: 18px;
                    margin-top: -6px;
                    transition: transform .4s;
                }
                &.open::after {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}

// Industries We Serve Section
.industries-we-serve-wrapper {
    background: #f8f8f8;
    padding: 104px 16px 100px;
    .industries-wrapper {
        margin: 40px auto 0;
        max-width: 1008px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
            margin-top: 70px;
            width: 48%;
            display: flex;
            align-items: flex-start;
            .item-img {
                width: 142px;
                text-align: center;
                img {
                    max-width: 100%;
                }
            }
            .item-info {
                flex: 1;
                margin-left: 24px;
                .item-name {
                    font-size: 22px;
                    font-weight: 500;
                    color: #212121;
                }
                .item-desc {
                    font-size: 14px;
                    line-height: 1.4;
                    color: #333;
                    margin-top: 10px;
                }
            }
        }
    }
}

// System Features Section
.system-features-wrapper {
    padding: 104px 16px 134px;
    .fs-sub {
        margin: 26px auto 0;
        max-width: 700px;
        text-align: center;
    }
    .sf-main {
        margin: 50px auto 0;
        max-width: 1008px;
        // height: 890px;
        background: #fff get_prefix_url("imgs/main/sf_bg.jpg") no-repeat left top;
        background-size: 60% auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .item {
            width: 50%;
            box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.15);
            border-radius: 0 0 0 20px;
            margin-bottom: 25px;
            overflow: hidden;
            .item-name {
                padding: 9px 20px;
                background: #00c4b3;
                font-size: 18px;
                line-height: 1.2;
                font-weight: bold;
                color: #fff;
            }
            .item-desc {
                padding: 9px 20px 15px;
                background: #fff;
                font-size: 14px;
                line-height: 1.2;
                color: #444;
                font-weight: 500;
                a {
                    color: #00c4be;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

// Fulfillment Service Network Section
.network-wrapper {
    padding: 104px 16px 120px;
    background: #f8f8f8;
    .fs-sub {
        margin: 26px auto 0;
        max-width: 700px;
        text-align: center;
    }
    .network-logo-list {
        margin: 0 auto;
        max-width: 1008px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .item {
            width: 30%;
            margin-top: 40px;
            text-align: center;
            img {
                max-width: 100%;
                max-height: 120px;
            }
        }
    }
}

// Blog Section
.main-blog-wrapper {
    padding: 104px 16px 120px;
    .blog-list {
        .blog-container {
            margin: 50px auto 0;
            max-width: 1008px;
            overflow: visible;
        }
        .swiper-wrapper {
            height: auto;
            align-items: stretch;
            justify-content: space-between;
        }
        .swiper-slide {
            width: 32%;
            height: auto;
            background: #fff;
            box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            transition: box-shadow .3s;
            overflow: hidden;
            &:hover {
                box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.3);
            }
        }
        .swiper-button-next, .swiper-button-prev {
            display: none;
        }
        .blog-item {
            display: block;
            width: 100%;
            .item-img {
                img {
                    width: 100%;
                    // height: 160px;
                    object-fit: cover;
                }
            }
            .item-box {
                padding: 16px 26px 32px;
            }
            .item-name {
                font-size: 22px;
                line-height: 1.2;
                font-weight: 500;
                color: #444;
            }
            .item-desc {
                font-size: 14px;
                line-height: 1.4;
                color: #333;
                margin-top: 14px;
            }
            .item-link {
                margin-top: 18px;
                font-size: 14px;
                color: #00c4b3;
                font-weight: 500;
            }
        }
    }
}

// Bottom Form Section
.bform-wrapper {
    .bf-sub-title {
        text-align: center;
        color: #fff;
        font-size: 18px;
        margin-top: 4px;
        a {
            color: #fff;
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 1100px) {
    .main-banner-c-wrapper .banner-main {
        width: 64%;
    }
    .main-banner-c-wrapper .main-info {
        padding: 20px;
    }
}

@media screen and (max-width: 1030px) {
    .main-services-wrapper .services-list .item .item-desc {
        font-size: 13px;
    }
    .main-banner-b-wrapper .main-info {
        padding: 40px 24px;
    }
    .main-banner-b-wrapper .slogan-title {
        font-size: 22px;
    }
    .main-banner-b-wrapper .banner-desc {
        font-size: 14px;
    }
    .main-features-wrapper .fea-banner {
        width: 400px;
    }
}
@media screen and (max-width: 900px) {
    .main-banner-c-wrapper {
        margin-top: 50px;
    }
}
@media screen and (max-width: 860px) {
    .main-services-wrapper .services-list .item .item-desc {
        font-size: 12px;
        line-height: 1.1;
        .main-zh & {
            line-height: 1.6;
        }
    }
    .system-features-wrapper .sf-main {
        background-size: auto 100%;
    }
    .main-services-wrapper .services-list .item .inner:hover .item-img {
        height: 50px;
    }
    .main-services-wrapper .services-list .item .inner:hover .item-name {
        font-size: 14px;
    }
    .main-features-wrapper .fea-banner {
        width: 350px;
    }
    .main-features-wrapper .fea-list .item .item-header .item-name {
        font-size: 14px;
    }
    .main-features-wrapper .fea-list .item .item-content {
        line-height: 14px;
    }
    .main-banner-a-wrapper {
        min-height: 500px;
    }
    .main-banner-a-wrapper .banner-link-wrap .btn {
        width: 200px;
    }
}

@media screen and (max-width: 767px) {
    div.home-title h2 {
        font-size: 22px;
    }
    .header-bar {
        top: 40px;
    }
    .logistics-shadow {
        top: 90px;
        height: calc(100vh - 90px);
    }
    .sider-bar-logistics {
        top: 90px;
    }
    // Banner Section
    .main-banner-a-wrapper {
        margin-top: 50px;
        min-height: 670px;
        background: #fff get_prefix_url("imgs/main/banner_m2.jpg") no-repeat center bottom;
        background-size: cover;
        .banner-main {
            width: 100%;
            background: linear-gradient(180deg, #2B4153 0%, rgba(38, 41, 44, 0.442708) 40%, rgba(196, 196, 196, 0) 50%);
        }
        .slogan-title {
            font-size: 26px;
            text-align: center;
            .main-zh & {
                font-size: 28px;
            }
        }
        .banner-desc {
            font-size: 16px;
            text-align: center;
        }
        .banner-link-wrap {
            margin-top: 265px;
            .btn {
                width: 267px!important;
                height: 48px!important;
                padding: 0!important;
                line-height: 48px;
            }
            .link-free {
                display: none;
            }
        }
    }
    
    .main-banner-b-wrapper {
        width: 100%;
        margin-top: 50px;
        min-height: 440px;
        background: #fff get_prefix_url("imgs/main/banner_f.jpg") no-repeat center top;
        background-size: 100% auto;
        position: relative;
        .banner-main {
            position: static;
            width: 100%;
            height: auto;
            display: block;
            padding-top: 300px;
        }
        .main-info {
            margin: 0 8px;
            padding: 27px 20px;
            background: #2B4153;
        }
        .slogan-title {
            font-size: 22px;
            line-height: 1.2;
            .main-zh & {
                font-size: 18px;
            }
        }
        .banner-desc {
            font-size: 14px;
            margin-top: 14px;
            .main-zh & {
                font-size: 12px;
            }
        }
        .main-form-wrap {
            padding: 0 16px;
            .banner-form {
                margin-top: 10px;
            }
            .input-group {
                margin-bottom: 0;
                flex-direction: column;
            }
            .input-ctrl {
                width: 100%;
                margin-top: 14px;
            }
            .txt {
                width: 100%;
            }
            .button-wrap {
                position: relative;
                .for-free-cons {
                    display: none;
                }
                .btn {
                    width: 166px !important;
                    height: 46px !important;
                    font-size: 15px;
                    letter-spacing: 1px;
                }
            }
        }
    }
    
    .main-banner-c-wrapper {
        width: 100%;
        margin-top: 50px;
        min-height: 440px;
        background: #fff get_prefix_url("imgs/main/banner_m_new.jpg") no-repeat center top;
        background-size: 100% auto;
        position: relative;
        .banner-main {
            position: static;
            width: 100%;
            height: auto;
            display: block;
            padding-top: 270px;
        }
        .main-info {
            margin: 0 8px;
            padding: 27px 20px;
            background: #2B4153;
            max-width: inherit;
        }
        .slogan-title {
            font-size: 22px;
            line-height: 1.2;
            .main-zh & {
                font-size: 18px;
            }
        }
        .banner-desc {
            font-size: 14px;
            margin-top: 14px;
            .main-zh & {
                font-size: 12px;
            }
        }
        .main-form-wrap {
            padding: 0 10px;
            .banner-form {
                margin-top: 0;
            }
            .input-group {
                margin-bottom: 0;
                flex-direction: column;
            }
            .default-hide-in-mobile {
                display: none;
            }
            .input-ctrl {
                width: 100%;
                margin-top: 14px;
            }
            .txt {
                width: 100%;
            }
            .button-wrap {
                position: relative;
                .for-free-cons {
                    display: none;
                }
                .btn {
                    width: 166px !important;
                    height: 46px !important;
                    font-size: 15px;
                    letter-spacing: 1px;
                }
                .see-less {
                    display: none;
                    font-size: 14px;
                    color: #fff;
                    text-decoration: underline;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
            }
        }
        
    }
    
    // How It Works Section
    .main-howitworks-wrapper {
        padding: 60px 50px 60px;
        .hiw-list {
            .item {
                margin-top: 40px;
            }
            .item-info {
                display: block;
                text-align: center;
            }
            .item-img {
                display: none;
            }
            .mobile-img {
                text-align: center;
                margin-top: 20px;
                img {
                    max-width: 100%;
                    max-height: 105px;
                    object-fit: cover;
                }
            }
            .item-id {
                font-size: 30px;
                color: #333;
            }
            .item-base {
                margin-left: 0;
                .item-name {
                    font-size: 16px;
                    color: #333;
                }
                .item-desc {
                    margin-top: 14px;
                    color: #374754;
                    .main-zh & {
                        line-height: 1.4;
                    }
                }
            }
        }
    }
    
    // Services Section
    .main-services-wrapper {
        padding: 45px 30px 60px;
        .services-list {
            margin: 40px auto 0;
            flex-direction: column;
            .item {
                height: auto;
                display: block;
                margin-bottom: 20px;
                width: 100%;
                .inner {
                    box-shadow: 0px 0px 10px rgba(51, 51, 51, 0.18);
                    border-radius: 10px;
                    border: 0 none;
                    padding: 5px 15px;
                    height: auto;
                }
                .item-img {
                    height: 60px;
                    margin-top: 5px;
                }
                .item-name {
                    color: #000;
                    margin-top: 4px;
                    font-size: 16px;
                }
                .item-desc {
                    font-size: 14px;
                    text-align: center;
                    margin-top: 4px;
                    padding-bottom: 10px;
                    height: auto;
                    opacity: 1;
                    .main-zh & {
                        line-height: 1.4;
                    }
                }
                .inner:hover, .inner.cur {
                    box-shadow: 0px 0px 10px rgba(51, 51, 51, 0.18);
                    border-radius: 10px;
                    border: 0 none;
                    height: auto;
                    .item-img {
                        height: 60px;
                        margin-top: 5px;
                    }
                    .item-name {
                        margin-top: 4px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    
    // Features Seciton
    .main-features-wrapper {
        padding: 45px 16px 60px;
        .fs-title {
            font-size: 16px;
        }
        .mfs-main {
            display: block;
            margin: 28px auto 0;
        }
        .fea-banner {
            display: none;
        }
        .fea-list {
            display: block;
            .item {
                margin-bottom: 8px;
                .item-header {
                    align-items: center;
                    background: get_prefix_url("imgs/main/dropdown.png") no-repeat 100% 14px;
                    .item-name {
                        margin: 0 0 0 4px;
                    }
                }
                .item-content {
                    padding-left: 0px;
                    height: 84px;
                    .main-zh & {
                        line-height: 1.4;
                    }
                }
                &.cur {
                    .item-header {
                        background: get_prefix_url("imgs/main/dropup.png") no-repeat 100% 14px;
                    }
                }
            }
        }
    }
    
    // Have a question Section
    .main-quesiton-wrapper {
        display: none;
    }
    
    // Comparisont table section
    .comparison-wrapper {
        padding: 45px 6px 60px;
        .compare-table-wrap {
            margin: 40px auto 0;
            box-shadow: none;
            padding: 0;
            table {
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                th {
                    width: 30%;
                    height: 0;
                    padding: 0;
                    > div {
                        padding: 12px 0;
                        margin: 0px !important;
                        font-size: 14px
                    }
                    &:nth-child(1) {
                        width: 40%;
                    }
                }
                tr {
                    td {
                        > div {
                            font-size: 14px;
                            margin: 0;
                            padding: 8px 0;
                            img {
                                width: 18px;
                            }
                        }
                        &:nth-child(1) > div {
                            padding-left: 5px;
                        }
                    }
                }
            }
            .btn-show-more {
                margin-top: 30px;
            }
        }
    }
    
    // Industries We Serve Section
    .industries-we-serve-wrapper {
        padding: 45px 16px 60px;
        .industries-wrapper {
            display: none;
        }
        .who-we-are-mobile {
            width: 297px;
            margin: 40px auto;
            position: relative;
        }
        .whoweare-container {
            height: 413px;
            border: 1px solid #dedede;
            box-sizing: border-box;
            box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.07);
            border-radius: 8.45349px;
            .swiper-slide {
                background: #fff;
                .item-img {
                    text-align: center;
                    margin-top: 56px;
                    img {
                        width: 108px;
                    }
                }
                .item-info {
                    text-align: center;
                    color: #374754;
                    .item-name {
                        font-size: 16px;
                        font-weight: bold;
                        margin-top: 33px;
                    }
                    .item-desc {
                        font-size: 14px;
                        line-height: 1.2;
                        margin-top: 14px;
                        padding: 0 20px;
                        .main-zh & {
                            line-height: 1.4;
                        }
                    }
                }
            }
        }
        .swiper-button-next, .swiper-button-prev {
            width: 48px;
            height: 48px;
            margin-top: -24px;
            &:focus {
                outline: none;
            }
        }
        .swiper-button-next {
            background: #f3f3f3 get_prefix_url("imgs/main/a_l.svg") no-repeat center center;
            transform: rotate(180deg);
            right: -24px;
            box-shadow: -1px -5px 6px rgba(0,0,0,.3);
            &:focus {
                background: #00c4b3 get_prefix_url("imgs/main/a_r.svg") no-repeat center center;
                transform: rotate(0);
                box-shadow: 1px 5px 6px rgba(0,0,0,.3);
            }
        }
        .swiper-button-prev {
            background: #f3f3f3 get_prefix_url("imgs/main/a_l.svg") no-repeat center center;
            left: -24px;
            box-shadow: -1px 5px 6px rgba(0,0,0,.3);
            &:focus {
                background: #00c4b3 get_prefix_url("imgs/main/a_r.svg") no-repeat center center;
                transform: rotate(180deg);
                box-shadow: 1px -5px 6px rgba(0,0,0,.3);
                
            }
        }
    }
    
    // System Features Section
    .system-features-wrapper {
        padding: 73px 0 40px;
        .fs-sub {
            font-size: 14px;
            padding: 0 20px;
            br {
                display: none;
            }
        }
        .sf-main {
            margin: 28px auto 0;
            background: #324a5e get_prefix_url("imgs/main/fea_m.jpg") no-repeat center 0;
            background-size: 100% auto;
            display: block;
            padding: 197px 16px 30px;
            .item {
                width: 100%;
                margin-bottom: 10px;
                border-radius: 0;
                .item-name {
                    padding: 9px 14px;
                    background: #00c4b3 get_prefix_url("imgs/main/ar_w.png") no-repeat 95% center;
                    font-size: 15px;
                }
                .item-desc {
                    padding: 9px 14px 12px;
                    display: none;
                    .main-zh & {
                        line-height: 1.4;
                    }
                }
                &.cur {
                    border-radius: 0 0 0 20px;
                    .item-desc {
                        display: block;
                    }
                }
            }
        }
    }
    
    // Fulfillment Service Network Section
    .network-wrapper {
        padding: 45px 16px 60px;
        background: #fff;
        .fs-sub {
            margin: 24px auto 0;
            font-size: 16px;
            line-height: 1.2;
            color: #000;
            .main-zh & {
                line-height: 1.4;
            }
        }
        .network-logo-list {
            .item {
                width: 50%;
                img {
                    max-height: 58px;
                }
                
            }
        }
    }
    
    // Blog Section
    .main-blog-wrapper {
        padding: 45px 16px 80px;
        background: #fafafa;
        overflow: hidden;
        .blog-list {
            position: relative;
            .blog-container {
                margin: 40px auto 0;
                height: 380px;
                width: 318px;
                box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                overflow: hidden;
                &.zh{
                    height: 415px;
                }
            }
            .swiper-wrapper {
                height: 100%;
                justify-content: flex-start;
            }
            .swiper-slide {
                width: 100%;
                height: 100%;
                background: #fff;
                box-shadow: none;
                border-radius: 0;
                &:hover {
                    box-shadow: none;
                }
            }
            .blog-item {
                display: block;
                width: 100%;
                .item-img {
                    img {
                        width: 100%;
                        height: 168px;
                        object-fit: cover;
                    }
                }
                .item-box {
                    padding: 18px 24px 0;
                }
                .item-name {
                    font-size: 16px;
                    font-weight: normal;
                    color: #081f32;
                }
                .item-desc {
                    font-size: 13px;
                    line-height: 1.3;
                    color: #374a59;
                    margin-top: 4px;
                }
                .item-link {
                    font-size: 12px;
                    font-weight: 600;
                }
                
            }
            .swiper-pagination {
                bottom: -30px;
                width: 100%;
            }
            .swiper-pagination-bullet {
                width: 8px;
                height: 8px;
                background: #263238;
                opacity: .4;
                margin: 0 4px;
            }
            .swiper-pagination-bullet-active {
                background: #263238;
                opacity: 1;
            }
        }
    }
    
    // Bottom Form Section
    .bform-wrapper {
        .bf-title {
            font-size: 16px;
        }
        .bf-sub-title {
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 370px) {
    .industries-we-serve-wrapper .who-we-are-mobile {
        width: 260px;
    }
    .industries-we-serve-wrapper .swiper-button-next, .industries-we-serve-wrapper .swiper-button-prev {
        width: 40px;
        height: 40px;
        margin-top: -20px;
    }
    div.home-title h2 {
        font-size: 22px;
    }
    .main-blog-wrapper .blog-list .blog-container {
        width: 288px;
    }
    .bform-wrapper .bf-title {
        font-size: 15px;
    }
}